<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>类别</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              :disabled="!!form.id"
              placeholder="请选择类别"
            >
              <el-option
                v-for="item in typeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>原币</label>
          <el-form-item prop="sourceCurrency">
            <el-select
              v-model="form.sourceCurrency"
              disabled
              style="width: 254px"
              placeholder="请选择原币"
            >
              <el-option
                v-for="item in commOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="addClass" @click="addClass">管理币制</span>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>外币</label>
          <el-form-item prop="destinationCurrency">
            <el-select
              v-model="form.destinationCurrency"
              :disabled="!!form.id"
              style="width: 254px"
              placeholder="请选择外币"
            >
              <el-option
                v-for="item in commOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="addClass" @click="addClass">管理币制</span>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>原币币值</label>
          <el-form-item prop="sourceValue">
            <el-input-number
              v-model="form.sourceValue"
              disabled
              style="width: 100%"
              controls-position="right"
              :min="0.001"
              :precision="3"
              :step="0.1"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>外币币值</label>
          <el-form-item prop="destinationValue">
            <el-input-number
              v-model="form.destinationValue"
              :disabled="!!form.id"
              style="width: 100%"
              controls-position="right"
              :min="0.0"
              :precision="3"
              :step="0.1"
              @change="handleDestinationChange"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>开始时间</label>
          <el-form-item prop="validFrom">
            <el-date-picker
              v-model="form.validFrom"
              :disabled="!!form.id"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>截止时间</label>
          <el-form-item prop="validTo">
            <el-date-picker
              v-model="form.validTo"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable">汇率</label>
          <el-form-item prop="rateValue">
            <el-input
              v-model="form.rateValue"
              disabled
              placeholder="自动计算"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <CommDialog
      :dialogVisible="dialogVisible2"
      :dataColumn="commOptions"
      title="币制"
      :type="7"
      @ok="cancel2"
      @cancel="cancel2"
      @dataChange="dataChange"
    ></CommDialog>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { getSysParams } from '@/api/system'

import { addExchangeRate, updateExchangeRate } from '@/api/fee'
const CommDialog = () => import('@/components/dialog/CommDialog')
export default {
  components: { CommDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    const validateDestinationValue = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入外币币值'))
      } else {
        if (value > 0) {
          callback()
        } else {
          callback(new Error('外币币值必须大于0'))
        }
      }
    }
    return {
      form: {
        type: '',
        sourceCurrency: 'RMB',
        sourceValue: 100,
        destinationCurrency: '',
        destinationValue: 0.0,
        rateValue: 0.0,
        validFrom: '',
        validTo: ''
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        sourceCurrency: [
          { required: true, message: '请选择原币', trigger: 'change' }
        ],
        sourceValue: [
          { required: true, message: '请输入原币币值', trigger: 'change' }
        ],
        destinationCurrency: [
          { required: true, message: '请选择外币', trigger: 'change' }
        ],
        destinationValue: [
          { required: true, message: '请输入外币币值', trigger: 'change' },
          { validator: validateDestinationValue, trigger: 'blur' }
        ],
        validTo: [
          { required: true, message: '请选择开始日期', trigger: 'change' }
        ],
        validFrom: [
          { required: true, message: '请选择截止日期', trigger: 'change' }
        ]
      },
      dialogVisible2: false,
      commData: [],
      typeOption: [
        {
          value: 0,
          label: '应收'
        },
        {
          value: 1,
          label: '应付'
        }
      ],
      sourceOption: [],
      destinationOption: []
    }
  },
  computed: {
    ...mapGetters(['userRole', 'addressDropOption', 'userTypeOptions', 'sign']),
    userRoleIdOption: function() {
      const arr = []
      this.userRole &&
        this.userRole.map((item) => {
          arr.push({
            value: item.id,
            label: item.userRoleNameCH
          })
        })
      return arr
    },
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        if (this.editData.id) {
          this.form = {
            ...this.editData
          }
        } else {
          this.$refs['form']?.resetFields()
          this.form = {
            type: '',
            sourceCurrency: 'RMB',
            sourceValue: 100,
            destinationCurrency: '',
            destinationValue: 0.0,
            rateValue: 0.0,
            validFrom: '',
            validTo: ''
          }
        }
      }
    }
  },
  created() {
    this.getCommData()
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.validFrom > this.form.validTo) {
            this.$message.error('截止日期必须大于开始日期')
            return
          }
          if (this.form.sourceCurrency === this.form.destinationCurrency) {
            this.$message.error('原币与外币不能相同')
            return
          }
          if (!this.form.id) {
            addExchangeRate(this.form).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            updateExchangeRate(this.form).then((res) => {
              if (res.success) {
                this.$message.success('修改成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        }
      })
    },
    handleDestinationChange(value) {
      this.form.rateValue = (this.form.sourceValue / value).toFixed(2)
    },
    addClass() {
      this.dialogVisible2 = true
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    getCommData() {
      getSysParams({ type: 7 }).then((res) => {
        console.log(res)
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    dataChange() {
      this.getCommData()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
  &:deep(.el-select) {
    width: 100%;
  }
  .addClass {
    position: absolute;
    width: 84px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    cursor: pointer;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.title {
  margin-top: 30px;
  margin-block: 16px;
}
</style>
