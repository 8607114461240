<template>
  <div class="view-contanier">
    <div class="opt">
      <div
        v-permissson="'add'"
        class="btn"
        @click="add">
        <svg-icon className="icon" iconClass="add" />新增
      </div>
      <BaseFilter :isActive="isActive" :width="438" @sendFilter="sendFilter" @reset="reset">
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="40px">
            <el-form-item label="类型">
              <el-select
                v-model="filterList.type"
                style="width: 320px;"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原币">
              <el-select
                v-model="filterList.sourceCurrency"
                style="width: 320px;"
                placeholder="请选择原币"
              >
                <el-option
                  v-for="item in commOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="外币">
              <el-select
                v-model="filterList.destinationCurrency"
                style="width: 320px;"
                placeholder="请选择外币"
              >
                <el-option
                  v-for="item in commOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @sortChange="sortChange"
      >
        <template slot="type" slot-scope="row">
          {{ row.scope.type ? '应付' : '应收' }}
          <img class="outDate" :src="isOutDate(row.scope.validTo)" alt="">
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-button
              v-permissson="'update'"
              type="text"
              @click="update(row.scope)">修改</el-button>
          </div>
        </template>
      </BaseTable>
    </div>
    <rate-dialog
      :dialogVisible="dialogVisible"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></rate-dialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { getSysParams } from '@/api/system'
import { getExchangeRatePages } from '@/api/fee'
import { mapGetters } from 'vuex'
import RateDialog from './components/RateDialog.vue'
import dayjs from 'dayjs'
import outDateImg from '@/assets/images/system/outDate.png'
import { myMixins } from '@/mixins'
export default {
  name: 'Rate',
  components: { BaseTable, BaseFilter, RateDialog },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'type',
            label: '类别',
            fixed: 'left',
            isCustom: true,
            isSort: true
            // formatter: (row) => {
            //   return row.type ? '应付' : '应收'
            // }
          },
          {
            filed: 'sourceCurrency',
            label: '原币',
            isSort: true
          },
          {
            filed: 'destinationCurrency',
            label: '外币',
            isSort: true
          },
          {
            filed: 'sourceValue',
            label: '原币币值',
            isSort: true
          },
          {
            filed: 'destinationValue',
            label: '外币币值',
            isSort: true
          },
          {
            filed: 'rateValue',
            label: '汇率',
            isSort: true
          },
          {
            filed: 'validFrom',
            label: '开始日期',
            isSort: true,
            formatter: (row) =>
              row.validFrom ? row.validFrom.slice(0, row.validFrom.length - 9) : ''
          },
          {
            filed: 'validTo',
            label: '截止日期',
            isSort: true,
            formatter: (row) =>
              row.validTo ? row.validTo.slice(0, row.validTo.length - 9) : ''
          },
          {
            filed: 'opt',
            label: '操作',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0,
        tableRowClassName({ row }) {
          if (dayjs().isAfter(dayjs(row.validTo))) {
            return 'error-row'
          }
        }
      },
      height: 0,
      dialogVisible: false,
      editData: {},
      commData: [],
      filterList: {
        type: '',
        sourceCurrency: '',
        destinationCurrency: ''
      },
      typeOptions: [
        {
          label: '应付',
          value: 1
        },
        {
          label: '应收',
          value: 0
        }
      ],
      isActive: false,
      sortable: {
        sortField: undefined,
        sortBy: undefined
      }
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  created() {
    this.getList(this.pagerConfig)
    this.getCommData()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable,
              ...this.formatterFilter()
          }
        }
        getExchangeRatePages(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    update(row) {
      this.editData = row
      this.dialogVisible = true
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.sortable = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.sortable = {}
      }
      this.getList(this.pagerConfig)
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getList(params)
      this.cancel()
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    getCommData() {
      getSysParams({ type: 7 }).then((res) => {
        console.log(res)
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    sendFilter() {
      if (
        this.filterList.type !== '' ||
        this.filterList.sourceCurrency !== '' ||
        this.filterList.destinationCurrency !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
        const params = {
          pageIndex: 1,
          pageSize: 20
        }
        this.pagerConfig = params
        this.getList(params)
    },
    reset() {
      this.filterList = {
        type: '',
        sourceCurrency: '',
        destinationCurrency: ''
      }
      this.isActive = false
    },
    formatterFilter() {
        return {
            type: this.filterList.type !== '' ? this.filterList.type : undefined,
            sourceCurrency: this.filterList.sourceCurrency !== '' ? this.filterList.sourceCurrency : undefined,
            destinationCurrency: this.filterList.destinationCurrency !== '' ? this.filterList.destinationCurrency : undefined
        }
    },
    isOutDate(date) {
      if (dayjs().isAfter(dayjs(date))) {
        return outDateImg
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  /deep/ .el-table .error-row {
    background: #FFF4F3;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .outDate {
    position: absolute;
    top: 0;
    left: 0;
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>
