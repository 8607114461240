var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.id ? "修改" : "新增",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("类别"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !!_vm.form.id,
                            placeholder: "请选择类别",
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.typeOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("原币"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sourceCurrency" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "254px" },
                          attrs: { disabled: "", placeholder: "请选择原币" },
                          model: {
                            value: _vm.form.sourceCurrency,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sourceCurrency", $$v)
                            },
                            expression: "form.sourceCurrency",
                          },
                        },
                        _vm._l(_vm.commOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "addClass",
                          on: { click: _vm.addClass },
                        },
                        [_vm._v("管理币制")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("外币"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "destinationCurrency" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "254px" },
                          attrs: {
                            disabled: !!_vm.form.id,
                            placeholder: "请选择外币",
                          },
                          model: {
                            value: _vm.form.destinationCurrency,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "destinationCurrency", $$v)
                            },
                            expression: "form.destinationCurrency",
                          },
                        },
                        _vm._l(_vm.commOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "addClass",
                          on: { click: _vm.addClass },
                        },
                        [_vm._v("管理币制")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("原币币值"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sourceValue" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: "",
                          "controls-position": "right",
                          min: 0.001,
                          precision: 3,
                          step: 0.1,
                        },
                        model: {
                          value: _vm.form.sourceValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sourceValue", $$v)
                          },
                          expression: "form.sourceValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("外币币值"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "destinationValue" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: !!_vm.form.id,
                          "controls-position": "right",
                          min: 0.0,
                          precision: 3,
                          step: 0.1,
                        },
                        on: { change: _vm.handleDestinationChange },
                        model: {
                          value: _vm.form.destinationValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "destinationValue", $$v)
                          },
                          expression: "form.destinationValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("开始时间"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "validFrom" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: !!_vm.form.id,
                          "value-format": "yyyy-MM-dd",
                          align: "center",
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.form.validFrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "validFrom", $$v)
                          },
                          expression: "form.validFrom",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("截止时间"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "validTo" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          align: "center",
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.form.validTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "validTo", $$v)
                          },
                          expression: "form.validTo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [_vm._v("汇率")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "rateValue" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "自动计算" },
                        model: {
                          value: _vm.form.rateValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "rateValue", $$v)
                          },
                          expression: "form.rateValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
      _c("CommDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          dataColumn: _vm.commOptions,
          title: "币制",
          type: 7,
        },
        on: {
          ok: _vm.cancel2,
          cancel: _vm.cancel2,
          dataChange: _vm.dataChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }